input.AddFriend {
    height: 41px;
    width: 100%;
    border: 1px solid #e0e2e3;
    padding-left: 10px;
    padding-right: 10px;
    //outline: none;
    border-radius: 3px;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    width: calc(100% - 120px);
    display: inline-block;
    
    &:focus {
        outline: none;
    }
}
input#addFriendButton {
    height: 41px;
    width: 100%;
    border: 1px solid #e0e2e3;
    padding-left: 10px;
    padding-right: 10px;
    //outline: none;
    border-radius: 3px;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    width: '20px';
    display: inline-block;
}